.hero {
  background-image: linear-gradient(
      115deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("/public/Hero_Oxygen.jpg");
  background-position: center;
  background-size: cover;

  animation-duration: 60s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: banner-animate;
}


nav > a {
  margin-right: 4rem;
  cursor: pointer;
  position: relative;
}
nav > a::before {
  content: "";
  width: 10px;

  border-top: 3px solid transparent;
  position: absolute;
  top: 0;
  left: 50%;
  transition: all 200ms ease-out;
}
nav > a:hover::before {
  content: "";
  width: 100%;
  position: absolute;
  top: 0;
  left: 0%;
  border-top: 3px solid white;
  border-color: blueviolet;
}
@keyframes banner-animate {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: calc(100% - 100vw + 15px) 0;
  }
}
