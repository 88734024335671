@tailwind base;
@tailwind components;
@tailwind utilities;


.slick-prev::before, .slick-prev  {
    color:white !important;
    background: #1F70C1;
    border-radius: 50%;
    padding: .5rem;
    left: -53px !important;
}

.slick-next::before, .slick-next  {
    color:white !important;
    background: #1F70C1;
    border-radius: 50%;
    padding: .5rem;
    right: -48px !important;
} 