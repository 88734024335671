.history {
  margin: 0;
  padding: 0;
  background: rgb(230, 230, 230);

  color: rgb(50, 50, 50);
  font-family: "Open Sans", sans-serif;
  font-size: 112.5%;
  line-height: 1.6em;
}

/* ================ The Timeline ================ */

/* .timeline {
  position: relative;
  width: 660px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 1em 0;
  list-style-type: none;
}

.timeline:before {
  position: absolute;
  left: 50%;
  top: 0;
  content: " ";
  display: block;
  width: 6px;
  height: 100%;
  margin-left: -3px;
  background: rgb(80, 80, 80);
  background: -moz-linear-gradient(
    top,
    rgba(80, 80, 80, 0) 0%,
    rgb(80, 80, 80) 8%,
    rgb(80, 80, 80) 92%,
    rgba(80, 80, 80, 0) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(30, 87, 153, 1)),
    color-stop(100%, rgba(125, 185, 232, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(80, 80, 80, 0) 0%,
    rgb(80, 80, 80) 8%,
    rgb(80, 80, 80) 92%,
    rgba(80, 80, 80, 0) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(80, 80, 80, 0) 0%,
    rgb(80, 80, 80) 8%,
    rgb(80, 80, 80) 92%,
    rgba(80, 80, 80, 0) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(80, 80, 80, 0) 0%,
    rgb(80, 80, 80) 8%,
    rgb(80, 80, 80) 92%,
    rgba(80, 80, 80, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(80, 80, 80, 0) 0%,
    rgb(80, 80, 80) 8%,
    rgb(80, 80, 80) 92%,
    rgba(80, 80, 80, 0) 100%
  );

  z-index: 5;
}

.timeline li {
  padding: 1em 0;
}

.timeline li:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.direction-l {
  position: relative;
  width: 300px;
  float: left;
  text-align: right;
}

.direction-r {
  position: relative;
  width: 300px;
  float: right;
}

.flag-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.flag {
  position: relative;
  display: inline;
  background: rgb(248, 248, 248);
  padding: 6px 10px;
  border-radius: 5px;

  font-weight: 600;
  text-align: left;
}

.direction-l .flag {
  -webkit-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15),
    0 0 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}

.direction-r .flag {
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15),
    0 0 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}

.direction-l .flag:before,
.direction-r .flag:before {
  position: absolute;
  top: 50%;
  right: -35px;
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  background: #fff;
  border-radius: 10px;
  border: 4px solid rgb(129 140 248);
  z-index: 10;
}

.direction-r .flag:before {
  left: -40px;
}

.direction-l .flag:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-left-color: rgb(248, 248, 248);
  border-width: 8px;
  pointer-events: none;
}

.direction-r .flag:after {
  content: "";
  position: absolute;
  right: 100%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-right-color: rgb(248, 248, 248);
  border-width: 8px;
  pointer-events: none;
}

.time-wrapper {
  display: inline;

  line-height: 1em;
  font-size: 0.66666em;
  color: rgb(250, 80, 80);
  vertical-align: middle;
}

.direction-l .time-wrapper {
  float: left;
}

.direction-r .time-wrapper {
  float: right;
}

.time {
  display: inline-block;
  padding: 4px 6px;
  background: rgb(248, 248, 248);
}

.desc {
  margin: 1em 0.75em 0 0;

  font-size: 0.77777em;
  font-style: italic;
  line-height: 1.5em;
}

.direction-r .desc {
  margin: 1em 0 0 0.75em;
} */

.container {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translate(-50%, 0);
}
.container h1 {
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 20px;
}
.container .box {
  position: relative;
  width: 100%;
  height: 330px;
  clear: both;
}
.container .box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background: #d5d5d5;
}
.container .box::after {
  content: "";
  position: absolute;
  top: 16%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  background: #003352;
  box-shadow: 0 0 0 5px rgba(110, 138, 252, 0.4);
  border-radius: 50%;
}
.container .box .info-box {
  position: relative;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 40px 20px;
}
.container .box:nth-of-type(2n + 1) .info-box {
  float: left;
  text-align: right;
}
.container .box:nth-of-type(2n) .info-box {
  float: right;
  text-align: left;
}
.container .box .info-box h1 {
  color: #003352;
  margin-bottom: 10px;
  letter-spacing: normal;
}
.container .box:nth-of-type(2n + 1) .info-box h1 {
  text-align: right;
}
.container .box:nth-of-type(2n) .info-box h1 {
  text-align: left;
}
.container .box .info-box p {
  line-height: 23px;
}
.container .box .date-box {
  position: relative;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 33px 35px;
}
.container .box:nth-of-type(2n + 1) .date-box {
  float: right;
  text-align: left;
  padding-right: 0;
}
.container .box:nth-of-type(2n) .date-box {
  float: left;
  text-align: right;
  padding-left: 0;
}
.container .box .date-box span {
  font-size: 18px;
  background: #003352;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  display: inline-block;
}

/* @media screen and (max-width: 700px) {
  .container {
    left: 10rem;
  }
  .container h1 {
    font-size: 30px;
    text-align: left;
  }
  .container .box {
    width: 90vw;
    height: auto;
  }
  .container .box::before {
    left: 0;
    transform: translateX(0);
  }
  .container .box::after {
    left: 0%;
    top: 10%;
  }
  .container .box .info-box {
    width: 100%;
    height: auto;
    padding: 5px 20px;
  }
  .container .box:nth-child(2n) .info-box,
  .container .box:nth-child(2n + 1) .info-box {
    text-align: left;
    float: none;
  }
  .container .box:nth-child(2n) .info-box h1,
  .container .box:nth-child(2n + 1) .info-box h1 {
    text-align: left;
  }

  .container .box .date-box {
    width: 100%;
    height: auto;
    padding: 10px 20px;
  }
  .container .box:nth-child(2n) .date-box,
  .container .box:nth-child(2n + 1) .date-box {
    text-align: left;
    float: none;
  }
} */
/* ================ Timeline Media Queries ================ */

/* @media screen and (max-width: 660px) {
  .timeline {
    width: 100%;
    padding: 4em 0 1em 0;
  }

  .timeline li {
    padding: 2em 0;
  }

  .direction-l,
  .direction-r {
    float: none;
    width: 100%;

    text-align: center;
  }

  .flag-wrapper {
    text-align: center;
  }

  .flag {
    background: rgb(255, 255, 255);
    z-index: 15;
  }

  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: -30px;
    left: 50%;
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin-left: -9px;
    background: #fff;
    border-radius: 10px;
    border: 4px solid rgb(255, 80, 80);
    z-index: 0;
  }

  .direction-l .flag:after,
  .direction-r .flag:after {
    content: "";
    position: absolute;
    left: 50%;
    top: -8px;
    height: 0;
    width: 0;
    margin-left: -8px;
    border: solid transparent;
    border-bottom-color: rgb(255, 255, 255);
    border-width: 8px;
    pointer-events: none;
  }

  .time-wrapper {
    display: block;
    position: relative;
    margin: 4px 0 0 0;
    z-index: 14;
  }

  .direction-l .time-wrapper {
    float: none;
  }

  .direction-r .time-wrapper {
    float: none;
  }

  .desc {
    position: relative;
    margin: 1em 0 0 0;
    padding: 1em;
    background: rgb(245, 245, 245);
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);

    z-index: 15;
  }

  .direction-l .desc,
  .direction-r .desc {
    position: relative;
    margin: 1em 1em 0 1em;
    padding: 1em;

    z-index: 15;
  }
} */

/* Tooltip container */
.tooltip {
  display: inline-block;
  position: relative;

  text-align: left;
}

.tooltip .bottom {
  min-width: 400px;
  top: 60px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px 20px;
  color: #444444;
  background-color: white;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  /* box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5); */
  display: none;
}

.tooltip:hover .bottom {
  display: block;
}

.tooltip .bottom i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip .bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: white;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

/* @media screen and (min-width: 400px ?? max-width: 660px) {
  .direction-l .desc,
  .direction-r .desc {
    margin: 1em 4em 0 4em;
  }
} */
