article > h2 {
  padding-left: 10px;
}
.readmore {
  position: relative;
  max-height: 100px;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 20px;

  transition: all 200ms ease;
}

.readmore-link {
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline;
  width: 100%;
  height: 60px;

  text-align: left;
  font-weight: bold;
  font-size: 16px;
  padding-top: 40px;
  background-image: linear-gradient(to bottom, transparent, white);
  cursor: pointer;
}
.readmore-link {
  /* content: "Read more"; */
  margin-left: 10px;
}

.readmore.expand {
  max-height: fit-content !important;
  transition: all 200ms ease;
}

.readmore-link.expand {
  /* content: "Read less"; */
  margin-left: 0px;
}
.readmore-link.expand {
  display: block;
  text-align: left;
}

.readmore-link.expand {
  position: relative;
  background-image: none;
  padding-top: 10px;
  height: 20px;
}
